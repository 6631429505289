import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import './index.less';

const GlobalFooter = ({
  show = true,
  className,
  isPasswordProtected,
}: {
  show?: boolean;
  className?: string;
  isPasswordProtected?: boolean;
}) => {
  if (!show) {
    return <></>;
  }
  const cls = classNames('global-footer-warp', className);
  return (
    <div className={cls}>
      <Image
        src={isPasswordProtected ? '/images/whitelogo.png' : '/images/layouts/footer-logo.png'}
        alt="Pietra Creator Marketplace"
        title="Pietra Creator Marketplace"
        className="logo"
        width={193}
        height={48}
      />
      <div className="section">
        <Link href="https://www.instagram.com/shoppietra" target={'_blank'} className="link">
          Instagram
        </Link>
        <Link href="https://www.tiktok.com/@shoppietra" target={'_blank'} className="link">
          TikTok
        </Link>
      </div>
    </div>
  );
};

export default GlobalFooter;
